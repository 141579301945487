<template>
  <div class="navigation-buttons">
    <slot />
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
@import '@core/scss/base/bootstrap-extended/_include';
@import '@core/scss/base/components/_include';

.navigation-buttons {
  margin-bottom: 20px;
}
</style>
