<template>
  <button
    v-touch:start="onStart"
    v-touch:tap="onTap"
    v-touch:longtap="onLongTap"
    v-touch:moving="onEnd"
    v-touch:end="onEnd"
    :class="[{'hold': holdOn}, color]"
  >
    <slot />
  </button>
</template>

<script>
export default {
  components: {},
  props: {
    disable: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'none',
    },
  },
  data() {
    return {
      holdOn: false,
    }
  },
  methods: {
    onStart(e) {
      if (this.disable) return
      this.holdOn = true
      this.$emit('actionStart', e)
    },
    async onTap(e) {
      if (this.disable) return

      this.holdOn = true
      this.$emit('action', e)
      await window.sleep(200)
      this.holdOn = false
    },
    async onLongTap(e) {
      if (this.disable) return

      this.holdOn = true
      this.$emit('actionLongTap', e)
      await window.sleep(200)
      this.holdOn = false
    },
    onEnd(e) {
      this.$nextTick(async () => {
        await window.sleep(200)
        this.holdOn = false
        this.$emit('actionEnd', e)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@use "sass:map";
@import '@core/scss/base/bootstrap-extended/_include';
@import '@core/scss/base/components/_include';

button {
  box-shadow: 0 6px 13px rgb(0 0 0 / 12%);
  background-color: #ffffff;
  height: 35px;
  width: 35px;
  font-size: 1.2rem;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 20px;
  color: $gray-800;

  &.hold {
    background-color: #a1a1a1;
  }

  i {
    position: relative;
    top: 1px;
  }

  @each $name, $value in $theme-colors {
    &.#{$name} {
      background-color: $value;
      color: map.get($text-color-with-background-color, $name);

      &.hold {
        background-color: darken($value, 10) !important;
      }

      &.disabled {
        opacity: 0.6;
      }
    }
  }
}
</style>
